import { Helmet } from "react-helmet-async";
import { Card, CardHeader, Container, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useChart } from "../components/chart";
import Chart from "react-apexcharts";
import React from "react";

export const formatGroupPerformanceData = (groupPerformanceInfoData) => {
  const groupedData = groupPerformanceInfoData.reduce((result, item) => {
    const { groupName, amount, subscriptionName } = item;
    const key = `${groupName}-${subscriptionName}`;
    if (result[key]) {
      result[key].amount += amount;
    } else {
      result[key] = { groupName, amount, subscriptionName };
    }
    return result;
  }, {});

  return Object.values(groupedData);
};

export default function GroupPage() {
  const { state } = useLocation();

  const data = state ? formatGroupPerformanceData(state.data.resultList) : [];

  const chartOptionsSubs = useChart(
    state
      ? {
          xaxis: {
            categories: data.map((item) => item.subscriptionName),
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "R$ " + val;
              },
            },
          },
        }
      : null
  );

  const chartOptionsAff = useChart(
    state
      ? {
          xaxis: {
            categories: [state?.data?.resultList?.[0].groupName ?? ""],
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "R$ " + val;
              },
            },
          },
        }
      : null
  );

  return (
    <>
      <Helmet>
        <title> Grupo | Orbis </title>
      </Helmet>

      <Container maxWidth="xl">
        {state ? (
          <>
            <Typography mb={5} variant="h4">
              {state.data.resultList[0].groupName}
            </Typography>
            <Stack gap={3}>
              <Card style={{ height: "100%" }}>
                <CardHeader title="Meus ganhos" />

                <Chart
                  options={chartOptionsAff}
                  series={[
                    {
                      name: "Meus ganhos",
                      data: [state.data.affiliatesEarnings],
                    },
                  ]}
                  type="bar"
                  height={300}
                />
              </Card>

              <Card style={{ height: "100%" }}>
                <CardHeader
                  title="Ganhos do grupo por assinatura"
                  subheader={`Ganhos do grupo com base na assinatura.`}
                />

                <Chart
                  options={chartOptionsSubs}
                  series={[
                    {
                      name: "Ganhos do Grupo",
                      data: data.map((item) => item.amount.toFixed(2)),
                    },
                  ]}
                  type="bar"
                  height={300}
                />
              </Card>
            </Stack>
          </>
        ) : (
          <Typography variant="h4" gutterBottom>
            Nada é encontrado...
          </Typography>
        )}
      </Container>
    </>
  );
}

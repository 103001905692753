import { Stack } from "@mui/material";
import UsersStatistics from "./UsersStatistics";
import SubscriptionStatistics from "./SubscriptionStatistics";
import React, { useState } from "react";
import GroupsStatistics from "./GroupsStatistics";
import GroupsPerformanceStatistics from "./GroupsPerformanceStatistics";
import EarningsStatistics from "./EarningsStatistics";

const date = new Date();
const dateRangeInitialValue = [
  {
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(),
    key: "selection",
  },
];

const Statistics = () => {
  const [timeRange, setTimeRange] = useState("MONTH");
  const [dateRange, setDateRange] = useState(dateRangeInitialValue);

  return (
    <Stack gap={4}>
      <EarningsStatistics
        setTimeRange={setTimeRange}
        setDateRange={setDateRange}
        dateRange={dateRange}
        timeRange={timeRange}
      />
      <UsersStatistics dateRange={dateRange} timeRange={timeRange} />
      <SubscriptionStatistics dateRange={dateRange} timeRange={timeRange} />
      <GroupsStatistics dateRange={dateRange} timeRange={timeRange} />
      <GroupsPerformanceStatistics
        dateRange={dateRange}
        timeRange={timeRange}
      />
    </Stack>
  );
};

export default Statistics;

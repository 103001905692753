import { Card, CardHeader } from "@mui/material";
import Chart from "react-apexcharts";
import React from "react";
import { useChart } from "./index";

const SingleBarChart = ({ options, title, name, data }) => {
  const chartOptions = useChart({
    xaxis: {
      categories: [""],
    },
    ...options,
  });

  return (
    <Card>
      <CardHeader title={title} />

      <Chart
        options={chartOptions}
        series={[
          {
            name,
            data: [data],
          },
        ]}
        type="bar"
        height={300}
      />
    </Card>
  );
};

export default SingleBarChart;

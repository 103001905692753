import { useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { toast } from "react-toastify";
import transformYupError from "../../utils/transformYupError";
import api from "../../api";

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const useForgotPassword = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const forgotPasswordMutation = useMutation((forgotPasswordData) =>
    api
      .post("/forgotPassword", forgotPasswordData)
      .then((response) => response.data)
  );

  const forgotPassword = async (forgotPasswordData) => {
    try {
      await forgotPasswordSchema.validate(forgotPasswordData, {
        abortEarly: false,
      });
      const response = await forgotPasswordMutation.mutateAsync(
        forgotPasswordData
      );
      setData(response);
      setError(null);
      toast.success("Password reset link sent to your email");
    } catch (error) {
      if (error.name === "ValidationError") {
        setError(transformYupError(error));
        return;
      }

      setError(null);
    }
  };

  return {
    data,
    error,
    isLoading: forgotPasswordMutation.isLoading,
    forgotPassword,
  };
};

export default useForgotPassword;

import { useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import api from "../../api";
import transformYupError from "../../utils/transformYupError";

const passwordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .oneOf([Yup.ref("oldPassword"), null], "Passwords must match"),
});

const usePassword = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const passwordMutation = useMutation((passwordData) =>
    api.post("/password", passwordData).then((response) => response.data)
  );

  const updatePassword = async (passwordData) => {
    try {
      await passwordSchema.validate(passwordData, { abortEarly: false });
      const response = await passwordMutation.mutateAsync(passwordData);
      setData(response);
      setError(null);
    } catch (error) {
      if (error.name === "ValidationError") {
        setError(transformYupError(error));
        return;
      }

      setError(null);
    }
  };

  return { data, error, isLoading: passwordMutation.isLoading, updatePassword };
};

export default usePassword;

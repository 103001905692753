import {
  Card,
  CardHeader,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  CardContent,
} from "@mui/material";
import React from "react";

const calculateAveragePrice = (subscriptionInfoData) => {
  if (subscriptionInfoData?.length === 0) {
    return 0;
  }

  const totalPrices = subscriptionInfoData.reduce(
    (acc, info) => acc + info.price,
    0
  );

  return totalPrices / subscriptionInfoData?.length;
};

const SubscriptionsInfoChart = ({ subscriptionInfoData }) => {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="Informações de assinatura"
        subheader={
          <Typography variant="body2" color="#637381">
            The corresponding price, group name and date of creation for each
            subscription.
            <br />
            Preço Médio das Assinaturas Vendidas — R${" "}
            {calculateAveragePrice(subscriptionInfoData).toFixed(2)}
          </Typography>
        }
      />

      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: "300px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Nome do grupo</TableCell>
                <TableCell align="right">Nome da assinatura</TableCell>
                <TableCell align="right">Preço</TableCell>
                <TableCell align="right">Criar Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionInfoData.map(
                ({ groupName, price, createDate, subscriptionName }, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {groupName}
                    </TableCell>
                    <TableCell align="right">{subscriptionName}</TableCell>
                    <TableCell align="right">R$ {price.toFixed(2)}</TableCell>
                    <TableCell align="right">{createDate}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default SubscriptionsInfoChart;

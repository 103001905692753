import { Helmet } from "react-helmet-async";
import { Container, Link, Typography } from "@mui/material";
import { ResetPasswordForm } from "../sections/auth";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ROUTES from "../routes/routes";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function ResetPasswordPage() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Redefinir senha | Orbis </title>
      </Helmet>

      <Container maxWidth="sm">
        <StyledContent>
          <Typography variant="h4" gutterBottom>
            Redefinir senha
          </Typography>

          <Typography sx={{ mb: 5 }} variant="body2">
            Volte ao{" "}
            <Link onClick={() => navigate(ROUTES.login)} variant="subtitle2">
              Login
            </Link>
          </Typography>

          <ResetPasswordForm />
        </StyledContent>
      </Container>
    </>
  );
}

import { Helmet } from "react-helmet-async";
import { Container, Stack, Typography } from "@mui/material";
import { useUser } from "../hooks/auth";
import { StripeForm } from "../sections/stripe";

export default function ProfilePage() {
  const { user } = useUser();

  return (
    <>
      <Helmet>
        <title> Perfil | Orbis </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack gap={3}>
          <Stack gap={1}>
            <Typography variant="h4">{user.displayName}</Typography>
          </Stack>
          <StripeForm />
        </Stack>
      </Container>
    </>
  );
}

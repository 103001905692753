import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";
import { useUser } from "../../hooks/auth";

const Logo = ({ sx }) => {
  const { user } = useUser();

  const logo = (
    <Box
      component="img"
      src="/assets/logo.svg"
      sx={{ width: 40, height: 40, cursor: "pointer", ...sx }}
    />
  );

  return (
    <RouterLink
      to={user ? "/dashboard/app" : "/login"}
      sx={{ display: "contents" }}
    >
      {logo}
    </RouterLink>
  );
};

export default Logo;

import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import {
  usePartnerGroupPerformanceInfo,
  usePartnerGroupPerformanceStatistic,
} from "../../../hooks/statistics";
import GroupsPerformanceInfoChart from "../../../components/chart/GroupsPerformanceInfoChart";
import GroupsPerformanceStatisticsChart from "../../../components/chart/GroupsPerformanceStatisticsChart";

const GroupsPerformanceStatistics = ({ timeRange, dateRange }) => {
  const from = dateRange[0].startDate.toISOString().split("T")[0];
  const till = dateRange[0].endDate.toISOString().split("T")[0];

  const { data: groupPerformanceInfoData } = usePartnerGroupPerformanceInfo(
    from,
    till
  );

  const { data: groupPerformanceStatisticsData } =
    usePartnerGroupPerformanceStatistic(timeRange, from, till);

  return (
    <Stack gap={2}>
      <Typography variant="h4">Estatísticas de desempenho de grupos</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {groupPerformanceStatisticsData ? (
            <GroupsPerformanceStatisticsChart
              groupPerformanceStatisticsData={groupPerformanceStatisticsData}
            />
          ) : (
            <Skeleton variant="rounded" width="100%" height="367px" />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {groupPerformanceInfoData ? (
            <GroupsPerformanceInfoChart
              groupPerformanceInfoData={groupPerformanceInfoData}
            />
          ) : (
            <Skeleton variant="rounded" width="100%" height="367px" />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GroupsPerformanceStatistics;

import { Card, CardHeader } from "@mui/material";
import Chart from "react-apexcharts";
import React from "react";
import { useChart } from "./index";

const BarChart = ({ options, title, name, data, number = true, subheader }) => {
  const chartOptions = useChart({
    xaxis: {
      categories: data?.resultList?.map((item) => item.columnName),
    },
    ...options,
  });

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader title={title} subheader={subheader} />

      <Chart
        options={chartOptions}
        series={[
          {
            name,
            data: data?.resultList?.map((item) =>
              number ? item.number.toFixed(2) : item.amount.toFixed(1)
            ),
          },
        ]}
        type="bar"
        height={300}
      />
    </Card>
  );
};

export default BarChart;

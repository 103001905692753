import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const EarningsInfoChart = ({ earningsInfoData }) => {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="Ganhos por assinatura"
        subheader={`Os ganhos do afiliado correspondente por assinatura.`}
      />

      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: "340px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Nome da assinatura</TableCell>
                <TableCell>Nome do grupo</TableCell>
                <TableCell align="right">Quantia</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {earningsInfoData.map(
                (
                  { groupName, amount, createDate, subscriptionName },
                  index
                ) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{subscriptionName}</TableCell>
                    <TableCell component="th" scope="row">
                      {groupName}
                    </TableCell>
                    <TableCell align="right">R$ {amount.toFixed(2)}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default EarningsInfoChart;

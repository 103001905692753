import { useState } from "react";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForgotPassword } from "../../hooks/auth";

export default function ForgotPasswordForm() {
  const { error, isLoading, forgotPassword } = useForgotPassword();

  const [email, setEmail] = useState("");

  const handleClick = () => {
    forgotPassword({ email });
  };

  return (
    <Stack spacing={2}>
      <TextField
        error={!!error?.email}
        helperText={error?.email}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        name="email"
        label="Endereço de email"
        type="email"
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
      >
        Continuar
      </LoadingButton>
    </Stack>
  );
}

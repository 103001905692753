import React from "react";
import { useChart } from "./index";
import Chart from "react-apexcharts";
import { Card, CardHeader } from "@mui/material";

export const formatEarningsInfoData = (earningsInfoData) => {
  const groupedData = earningsInfoData.reduce((result, item) => {
    const { groupName, amount } = item;
    const key = `${groupName}`;
    if (result[key]) {
      result[key].amount += amount;
    } else {
      result[key] = { groupName, amount };
    }
    return result;
  }, {});

  return Object.values(groupedData);
};

const EarningsGroupChart = ({ earningsInfoData }) => {
  const data = formatEarningsInfoData(earningsInfoData);

  const chartOptions = useChart({
    xaxis: {
      categories: data.map((item) => item.groupName),
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "R$ " + val.toFixed(2);
        },
      },
    },
  });

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="Ganhos por grupo"
        subheader={`Os ganhos do afiliado correspondente por grupo.`}
      />

      <Chart
        options={chartOptions}
        series={[
          {
            name: "Ganhos por grupo",
            data: data.map((item) => item.amount.toFixed(2)),
          },
        ]}
        type="bar"
        height={340}
      />
    </Card>
  );
};

export default EarningsGroupChart;

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container, Link, Typography } from "@mui/material";
import { SignUpForm } from "../sections/auth";
import { styled } from "@mui/material/styles";
import ROUTES from "../routes/routes";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function SignUpPage() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Inscrever-se | Orbis </title>
      </Helmet>

      <Container maxWidth="sm">
        <StyledContent>
          <Typography variant="h4" gutterBottom>
            Inscreva-se na Orbis
          </Typography>

          <Typography variant="body2" sx={{ mb: 5 }}>
            Já possui uma conta?{" "}
            <Link onClick={() => navigate(ROUTES.login)} variant="subtitle2">
              Conecte-se
            </Link>
          </Typography>

          <SignUpForm />
        </StyledContent>
      </Container>
    </>
  );
}

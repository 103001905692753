import React from "react";
import { usePartnerUserStatistic } from "../../../hooks/statistics";
import { Skeleton, Stack, Typography } from "@mui/material";
import BarChart from "../../../components/chart/BarChart";

const UsersStatistics = ({ timeRange, dateRange }) => {
  const from = dateRange[0].startDate.toISOString().split("T")[0];
  const till = dateRange[0].endDate.toISOString().split("T")[0];

  const { data: userStatisticData } = usePartnerUserStatistic(
    timeRange,
    from,
    till
  );

  return (
    <Stack gap={2}>
      <Typography variant="h4">Estatísticas dos usuários</Typography>
      <Stack flexDirection={{ xs: "column", sm: "row" }} gap={3}>
        <Stack flex={1}>
          {userStatisticData ? (
            <BarChart
              data={userStatisticData}
              name="Número de usuários"
              title="Número de usuários"
              subheader="Número de usuários que se registraram no link do afiliado."
              options={{
                yaxis: [
                  {
                    labels: {
                      formatter: function (val) {
                        return val.toFixed(0);
                      },
                    },
                  },
                ],
              }}
            />
          ) : (
            <Skeleton variant="rounded" width="100%" height="389px" />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UsersStatistics;

const ROUTES = {
    dashboardApp: "/dashboard/app",
    dashboard: "/dashboard",
    login: "/login",
    signup: "/sign-up",
    profile: "/profile",
    resetPassword: "/reset-password",
    forgotPassword: "/forgot-password",
    notFound: "/404",
};

export default ROUTES;

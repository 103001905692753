import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify";
import { useLogin } from "../../hooks/auth";
import ROUTES from "../../routes/routes";

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const { error, isLoading, login } = useLogin();

  const onDataChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = () => {
    login(data);
  };

  return (
    <>
      <Stack spacing={2}>
        <TextField
          error={!!error?.email}
          helperText={error?.email}
          onChange={onDataChange}
          name="email"
          label="Endereço de email"
          value={data.email}
        />

        <TextField
          error={!!error?.password}
          helperText={error?.password}
          onChange={onDataChange}
          name="password"
          label="Senha"
          value={data.password}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 1 }}
      >
        <FormControlLabel
          control={<Checkbox name="remember" />}
          label="Lembre de mim"
        />

        <Link
          onClick={() => navigate(ROUTES.forgotPassword)}
          variant="subtitle2"
          underline="hover"
        >
          Esqueceu sua senha?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
      >
        Conecte-se
      </LoadingButton>
    </>
  );
}

import { MutationCache, QueryCache, QueryClient } from "react-query";
import { toast } from "react-toastify";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
      enabled: true,
    },
  },
  mutationCache: new MutationCache({
    onError: (e) => {
      toast.error(
        e?.response?.data?.message || e.message || `Something went wrong`
      );
    },
  }),
  queryCache: new QueryCache({
    onError: (e) => {
      toast.error(
        e?.response?.data?.message || e.message || `Something went wrong`
      );
    },
  }),
});

import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Header from "./header";
import Nav from "./nav";
import { useRefresh, useUser } from "../../hooks/auth";
import ROUTES from "../../routes/routes";
import { Backdrop, CircularProgress } from "@mui/material";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { user, updateUser } = useUser();
  const { isLoading: isRefreshingToken, mutateAsync: refreshToken } =
    useRefresh();

  useEffect(() => {
    let timeoutId;

    if (user) {
      refreshToken(user.refreshToken).then((idToken) =>
        updateUser({ ...user, idToken })
      );

      timeoutId = setTimeout(() => {
        refreshToken(user.refreshToken).then((idToken) =>
          updateUser({ ...user, idToken })
        );
      }, 1000 * 60 * 50);

      return () => clearTimeout(timeoutId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user && !isRefreshingToken) {
    return <Navigate to={ROUTES.login} />;
  }

  if (isRefreshingToken) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}

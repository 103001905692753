import { useState } from "react";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify";
import { useSignup } from "../../hooks/auth";

export default function SignUpForm() {
  const [showPassword, setShowPassword] = useState(false);
  const { signup, isLoading, error } = useSignup();

  const [data, setData] = useState({
    displayName: "",
    password: "",
    confirm_password: "",
    email: "",
  });

  const onDataChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = () => {
    signup(data);
  };

  return (
    <Stack spacing={2}>
      <TextField
        error={!!error?.displayName}
        helperText={error?.displayName}
        name="displayName"
        label="Nome de exibição"
        type="text"
        onChange={onDataChange}
        value={data.displayName}
      />
      <TextField
        error={!!error?.email}
        helperText={error?.email}
        name="email"
        label="Endereço de email"
        type="email"
        onChange={onDataChange}
        value={data.email}
      />

      <TextField
        error={!!error?.password}
        helperText={error?.password}
        name="password"
        label="Senha"
        type={showPassword ? "text" : "password"}
        onChange={onDataChange}
        value={data.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                <Iconify
                  icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        error={!!error?.confirm_password}
        helperText={error?.confirm_password}
        name="confirm_password"
        label="Confirme sua senha"
        type="password"
        onChange={onDataChange}
        value={data.confirm_password}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
      >
        Inscrever-se
      </LoadingButton>
    </Stack>
  );
}

import { useMutation } from "react-query";
import api from "../../api";

const useRefresh = () => {
  return useMutation((refreshToken) => {
    return api
      .post("/refresh", refreshToken, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => response.data);
  });
};

export default useRefresh;

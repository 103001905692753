import React from "react";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import {
  usePartnerSubscriptionInfo,
  usePartnerSubscriptionStatistic,
} from "../../../hooks/statistics";
import SubscriptionsInfoChart from "../../../components/chart/SubscriptionsInfoChart";
import SubscriptionsStatisticsChart from "../../../components/chart/SubscriptionsStatisticsChart";

const SubscriptionStatistics = ({ timeRange, dateRange }) => {
  const from = dateRange[0].startDate.toISOString().split("T")[0];
  const till = dateRange[0].endDate.toISOString().split("T")[0];

  const { data: subscriptionStatisticData } = usePartnerSubscriptionStatistic(
    timeRange,
    from,
    till
  );
  const { data: subscriptionInfoData } = usePartnerSubscriptionInfo(from, till);

  return (
    <Stack gap={2}>
      <Typography variant="h4">Estatísticas de assinaturas</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {subscriptionStatisticData ? (
            <SubscriptionsStatisticsChart
              subscriptionStatisticData={subscriptionStatisticData}
            />
          ) : (
            <Skeleton variant="rounded" width="100%" height="389px" />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {subscriptionInfoData ? (
            <SubscriptionsInfoChart
              subscriptionInfoData={subscriptionInfoData}
            />
          ) : (
            <Skeleton variant="rounded" width="100%" height="389px" />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SubscriptionStatistics;

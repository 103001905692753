import { Helmet } from "react-helmet-async";
import { Container, Typography } from "@mui/material";
import Statistics from "../sections/@dashboard/app";
import { useUser } from "../hooks/auth";

export default function DashboardAppPage() {
  const { user } = useUser();

  return (
    <>
      <Helmet>
        <title> Painel | Orbis </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h3">
          Olá, bem-vindo de volta {user.displayName}!
        </Typography>

        <Statistics />
      </Container>
    </>
  );
}

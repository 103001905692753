import useLocalStorage from "react-use-localstorage";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../react-query/constants";

const useUser = () => {
  const queryClient = useQueryClient();
  const [user, setUser] = useLocalStorage(queryKeys.user, null);

  const updateUser = (userData) => {
    queryClient.setQueryData(queryKeys.user, userData);
    setUser(JSON.stringify(userData));
  };

  const clearUser = () => {
    queryClient.removeQueries(queryKeys.user);
    setUser(null);
  };

  return { clearUser, updateUser, user: JSON.parse(user) };
};

export default useUser;

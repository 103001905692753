import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Label from "../label";
import { sentenceCase } from "change-case";

const GroupsInfoChart = ({ groupInfoData }) => {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="Número de membros por grupo"
        subheader="O nome do grupo correspondente, número de membros e status de ativação para cada assinatura."
      />

      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: "300px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Nome do grupo</TableCell>
                <TableCell align="right">Número de membros</TableCell>
                <TableCell align="right">Ativado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupInfoData.map(
                (
                  { groupName, members, createDate, subscriptionActivated },
                  index
                ) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {groupName}
                    </TableCell>
                    <TableCell align="center">{members}</TableCell>
                    <TableCell align="right">
                      <Label
                        color={subscriptionActivated ? "success" : "error"}
                      >
                        {sentenceCase(
                          subscriptionActivated ? "Ativo" : "Inativo"
                        )}
                      </Label>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default GroupsInfoChart;

import { useChart } from "./index";
import { Card, CardHeader } from "@mui/material";
import Chart from "react-apexcharts";
import React from "react";

const GroupsStatisticsChart = ({ groupStatisticsData }) => {
  const chartOptions = useChart({
    xaxis: {
      categories: groupStatisticsData.resultList?.map(
        (item) => item.columnName
      ),
    },
    yaxis: [
      {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    ],
  });

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="Número de grupos"
        subheader="O número de grupos criados usando o link do afiliado."
      />

      <Chart
        options={chartOptions}
        series={[
          {
            name: "Número de grupos",
            data: groupStatisticsData.resultList.map((item) =>
              item.number.toFixed(0)
            ),
          },
        ]}
        type="bar"
        height={300}
      />
    </Card>
  );
};

export default GroupsStatisticsChart;

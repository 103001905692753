import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import * as Yup from "yup";
import api from "../../api";
import transformYupError from "../../utils/transformYupError";
import { useUser } from "./index";
import ROUTES from "../../routes/routes";

const signupSchema = Yup.object().shape({
  displayName: Yup.string().required("Display Name is required"),
  password: Yup.string()
    .label("Password")
    .min(8)
    .required("Password is required"),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const useSignup = () => {
  const { updateUser } = useUser();

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const signupMutation = useMutation((signupData) =>
    api.post("/signup", signupData).then((response) => response.data)
  );

  const signup = async (signupData) => {
    try {
      await signupSchema.validate(signupData, { abortEarly: false });
      const response = await signupMutation.mutateAsync(signupData);
      updateUser(response);
      setData(response);
      setError(null);
      navigate(ROUTES.dashboard);
    } catch (error) {
      if (error.name === "ValidationError") {
        setError(transformYupError(error));
        return;
      }

      setError(null);
    }
  };

  return { data, error, isLoading: signupMutation.isLoading, signup };
};

export default useSignup;

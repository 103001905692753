import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const GroupsPerformanceInfoChart = ({ groupPerformanceInfoData }) => {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="Desempenho de Grupos"
        subheader={
          <Typography variant="body2" color="#637381">
            O desempenho dos grupos correspondentes.
            <br />
            Número de assinaturas vendidas — {groupPerformanceInfoData.length}
          </Typography>
        }
      />

      <CardContent>
        <TableContainer component={Paper} sx={{ maxHeight: "300px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Nome do grupo</TableCell>
                <TableCell align="right">Nome da assinatura</TableCell>
                <TableCell align="right">Quantia</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupPerformanceInfoData.map(
                (
                  { groupName, amount, createDate, subscriptionName },
                  index
                ) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {groupName}
                    </TableCell>
                    <TableCell align="right">{subscriptionName}</TableCell>
                    <TableCell align="right">R$ {amount.toFixed(2)}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default GroupsPerformanceInfoChart;

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import transformYupError from "../../utils/transformYupError";
import api from "../../api";
import { useUser } from "./index";
import ROUTES from "../../routes/routes";

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const useLogin = () => {
  const { updateUser } = useUser();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const loginMutation = useMutation((loginData) =>
    api.post("/login", loginData).then((response) => response.data)
  );

  const login = async (loginData) => {
    try {
      await loginSchema.validate(loginData, { abortEarly: false });
      const response = await loginMutation.mutateAsync(loginData);
      updateUser(response);
      setData(response);
      setError(null);
      navigate(ROUTES.dashboard);
    } catch (error) {
      if (error.name === "ValidationError") {
        setError(transformYupError(error));
        return;
      }

      setError(null);
    }
  };

  return { data, error, isLoading: loginMutation.isLoading, login };
};

export default useLogin;

import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import DashboardAppPage from "../pages/DashboardAppPage";
import AffiliatesPage from "../pages/AffiliatesPage";
import LoginPage from "../pages/LoginPage";
import SignUpPage from "../pages/SignUpPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import SimpleLayout from "../layouts/simple";
import Page404 from "../pages/Page404";
import ROUTES from "./routes";
import ProfilePage from "../pages/ProfilePage";
import GroupPage from "../pages/GroupPage";

export default function Router() {
  return useRoutes([
    {
      path: ROUTES.dashboard,
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={ROUTES.dashboardApp} />, index: true },
        {
          path: "app",
          element: <DashboardAppPage />,
        },
        { path: "groups", element: <AffiliatesPage /> },
        { path: "groups/:data", element: <GroupPage /> },
      ],
    },
    {
      element: <DashboardLayout />,
      children: [{ path: ROUTES.profile, element: <ProfilePage /> }],
    },
    {
      path: ROUTES.login,
      element: <LoginPage />,
    },
    {
      path: ROUTES.signup,
      element: <SignUpPage />,
    },
    {
      path: ROUTES.resetPassword,
      element: <ResetPasswordPage />,
    },
    {
      path: ROUTES.forgotPassword,
      element: <ForgotPasswordPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to={ROUTES.notFound} /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to={ROUTES.notFound} replace />,
    },
  ]);
}

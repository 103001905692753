import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { usePassword } from "../../hooks/auth";

export default function ResetPasswordForm() {
  const { error, isLoading, updatePassword } = usePassword();

  const [data, setData] = useState({
    email: "",
    oldPassword: "",
    newPassword: "",
  });

  const onDataChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = () => {
    updatePassword({ ...data, email: "test@mail.com" });
  };

  return (
    <Stack spacing={2}>
      <TextField
        value={data.oldPassword}
        error={!!error?.oldPassword}
        helperText={error?.oldPassword}
        onChange={onDataChange}
        name="oldPassword"
        label="Criar nova senha"
        type="password"
      />
      <TextField
        value={data.newPassword}
        error={!!error?.newPassword}
        helperText={error?.newPassword}
        onChange={onDataChange}
        name="newPassword"
        label="Confirme sua senha"
        type="password"
      />
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
        onClick={handleClick}
      >
        Continuar
      </LoadingButton>
    </Stack>
  );
}

import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState } from "react";
import {
  Card,
  CircularProgress,
  Container,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import {
  usePartnerEarningsInfo,
  usePartnerGroupInfo,
  usePartnerGroupPerformanceInfo,
} from "../hooks/statistics";
import { useUser } from "../hooks/auth";

const today = new Date().toISOString().split("T")[0];

const TABLE_HEAD = [
  { id: "groupName", label: "Nome do grupo", alignRight: false },
  { id: "createDate", label: "Data de criação", alignRight: false },
  { id: "amount", label: "Ganhos do grupo até o momento", alignRight: false },
  { id: "my_amount", label: "Meus ganhos do grupo", alignRight: false },

  {
    id: "numOfSubsSold",
    label: "Número de assinaturas vendidas",
    alignRight: false,
  },
  { id: "members", label: "Número de membros", alignRight: false },
  { id: "status", label: "Ativado", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (array === "") {
    return [];
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (group) =>
        group.groupName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AffiliatesPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected] = useState([]);

  const [orderBy, setOrderBy] = useState("groupName");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { user } = useUser();

  const navigate = useNavigate();

  const { data: groupInfo, isFetching: isGroupInfoFetching } =
    usePartnerGroupInfo(
      new Date(user.createTimestamp).toISOString().split("T")[0],
      today
    );

  const { data: groupPerformance, isFetching: isGroupPerformanceFetching } =
    usePartnerGroupPerformanceInfo(
      new Date(user.createTimestamp).toISOString().split("T")[0],
      today
    );

  const { data: earningsInfoData, isFetching: iseEarningsInfoDataLoading } =
    usePartnerEarningsInfo(
      new Date(user.createTimestamp).toISOString().split("T")[0],
      today
    );

  const isLoading =
    isGroupInfoFetching ||
    isGroupPerformanceFetching ||
    iseEarningsInfoDataLoading;

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - (isLoading ? [] : groupInfo).length
        )
      : 0;

  const filteredInfo = applySortFilter(
    isLoading ? [] : groupInfo,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredInfo.length && !!filterName;

  const subscriptionCountByGroup = {};

  if (groupPerformance) {
    groupPerformance.forEach((item) => {
      const groupName = item.groupName;
      if (subscriptionCountByGroup[groupName]) {
        subscriptionCountByGroup[groupName]++;
      } else {
        subscriptionCountByGroup[groupName] = 1;
      }
    });
  }

  let affiliatesEarnings = {};

  if (earningsInfoData) {
    affiliatesEarnings = earningsInfoData.reduce((result, item) => {
      const { groupName, amount } = item;
      const key = `${groupName}`;
      if (result[key]) {
        result[key].amount += amount;
      } else {
        result[key] = { groupName, amount };
      }
      return result;
    }, {});
  }

  const onRowClick = (groupName) => {
    navigate(groupName, {
      state: {
        data: {
          resultList: groupPerformance.filter(
            (item) => item.groupName === groupName
          ),
          affiliatesEarnings: affiliatesEarnings[groupName].amount,
        },
      },
    });
  };

  return (
    <>
      <Helmet>
        <title> Grupos | Orbis </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Groups
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={null}
                />

                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell
                        sx={{
                          height: "300px",
                        }}
                        colSpan={8}
                        align="center"
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {filteredInfo
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            groupName,
                            createDate,
                            members,
                            subscriptionActivated,
                          } = row;

                          const amount = groupPerformance
                            .filter((item) => item.groupName === groupName)
                            .reduce((acc, cur) => acc + cur.amount, 0)
                            .toFixed(2);

                          return (
                            <TableRow
                              onClick={() =>
                                subscriptionActivated && onRowClick(groupName)
                              }
                              hover
                              key={groupName}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{
                                cursor: subscriptionActivated
                                  ? "pointer"
                                  : "default",
                              }}
                            >
                              <TableCell padding="checkbox" />

                              <TableCell component="th" scope="row">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {groupName}
                                  </Typography>
                                </Stack>
                              </TableCell>

                              <TableCell align="left">{createDate}</TableCell>

                              <TableCell align="left">R$ {amount}</TableCell>

                              <TableCell align="left">
                                R${" "}
                                {affiliatesEarnings[groupName]?.amount.toFixed(
                                  2
                                ) ?? "0.00"}
                              </TableCell>

                              <TableCell align="left">
                                {subscriptionCountByGroup[groupName] ?? "0"}
                              </TableCell>

                              <TableCell align="left">{members}</TableCell>

                              <TableCell align="left">
                                <Label
                                  color={
                                    subscriptionActivated ? "success" : "error"
                                  }
                                >
                                  {sentenceCase(
                                    subscriptionActivated ? "Ativo" : "Inativo"
                                  )}
                                </Label>
                              </TableCell>
                              <TableCell align="left"></TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Tente verificar se há erros de digitação ou
                            usar palavras completas.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {!isLoading && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={groupInfo.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}

import { useQuery } from "react-query";

const useQueryFirstLoad = (...queryArgs) => {
  const query = useQuery(...queryArgs);

  const isFirstLoad = (query.isFetching || query.isLoading) && !query.data;

  return { ...query, isFirstLoad };
};

export default useQueryFirstLoad;

import { useQuery } from "react-query";
import api from "../../api";
import { useUser } from "../auth";

export const usePartnerUserStatistic = (type, from, till) => {
  const { user } = useUser();

  return useQuery(["partnerUserStatistic", type, from, till], async () => {
    const response = await api.get("/partner/statistic/users", {
      params: { type, from, till },
      headers: {
        Authorization: `Bearer ${user.idToken}`,
      },
    });
    return response.data;
  });
};

export const usePartnerSubscriptionStatistic = (type, from, till) => {
  const { user } = useUser();

  return useQuery(
    ["partnerSubscriptionStatistic", type, from, till],
    async () => {
      const response = await api.get("/partner/statistic/subscription", {
        params: { type, from, till },
        headers: {
          Authorization: `Bearer ${user.idToken}`,
        },
      });
      return response.data;
    }
  );
};

export const usePartnerSubscriptionInfo = (from, till) => {
  const { user } = useUser();

  return useQuery(["partnerSubscriptionInfo", from, till], async () => {
    const response = await api.get("/partner/statistic/subscription/info", {
      params: { from, till },
      headers: {
        Authorization: `Bearer ${user.idToken}`,
      },
    });
    return response.data;
  });
};

export const usePartnerGroupStatistic = (type, from, till) => {
  const { user } = useUser();

  return useQuery(["partnerGroupStatistic", type, from, till], async () => {
    const response = await api.get("/partner/statistic/groups", {
      params: { type, from, till },
      headers: {
        Authorization: `Bearer ${user.idToken}`,
      },
    });
    return response.data;
  });
};

export const usePartnerGroupInfo = (from, till) => {
  const { user } = useUser();

  return useQuery(["partnerGroupInfo", from, till], async () => {
    const response = await api.get("/partner/statistic/groups/info", {
      params: { from, till },
      headers: {
        Authorization: `Bearer ${user.idToken}`,
      },
    });
    return response.data;
  });
};

export const usePartnerGroupPerformanceStatistic = (type, from, till) => {
  const { user } = useUser();

  return useQuery(
    ["partnerGroupPerformanceStatistic", type, from, till],
    async () => {
      const response = await api.get("/partner/statistic/group-performance", {
        params: { type, from, till },
        headers: {
          Authorization: `Bearer ${user.idToken}`,
        },
      });
      return response.data;
    }
  );
};

export const usePartnerGroupPerformanceInfo = (from, till) => {
  const { user } = useUser();

  return useQuery(["partnerGroupPerformanceInfo", from, till], async () => {
    const response = await api.get(
      "/partner/statistic/group-performance/info",
      {
        params: { from, till },
        headers: {
          Authorization: `Bearer ${user.idToken}`,
        },
      }
    );
    return response.data;
  });
};

export const usePartnerEarningsStatistic = (type, from, till) => {
  const { user } = useUser();

  return useQuery(
    ["partnerEarningsStatistic", type, from, till],
    async () => {
      const response = await api.get("/partner/statistic/earning", {
        params: { type, from, till },
        headers: {
          Authorization: `Bearer ${user.idToken}`,
        },
      });
      return response.data;
    }
  );
};

export const usePartnerEarningsInfo = (from, till) => {
  const { user } = useUser();

  return useQuery(["partnerEarningsInfo", from, till], async () => {
    const response = await api.get("/partner/statistic/earning/info", {
      params: { from, till },
      headers: {
        Authorization: `Bearer ${user.idToken}`,
      },
    });
    return response.data;
  });
};

import { useChart } from "./index";
import Chart from "react-apexcharts";
import { Card, CardHeader } from "@mui/material";
import React from "react";

const EarningsStatisticsChart = ({ earningsStatisticsData }) => {
  const chartOptions = useChart({
    xaxis: {
      categories: earningsStatisticsData.resultList.map(
        (item) => item.columnName
      ),
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "R$ " + val.toFixed(2);
        },
      },
    },
  });

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="Ganhos"
        subheader={`Ganhos totais — R$ ${earningsStatisticsData.totalAmount.toFixed(
          2
        )}`}
      />

      <Chart
        options={chartOptions}
        series={[
          {
            name: "Ganhos do afiliado",
            data: earningsStatisticsData.resultList.map((item) =>
              item.amount.toFixed(2)
            ),
          },
        ]}
        type="bar"
        height={340}
      />
    </Card>
  );
};

export default EarningsStatisticsChart;

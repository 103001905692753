// component
import SvgColor from "../../../components/svg-color";
import ROUTES from "../../../routes/routes";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "painel",
    path: "/dashboard/app",
    icon: icon("ic_analytics"),
  },
  {
    title: "grupos",
    path: "/dashboard/groups",
    icon: icon("ic_user"),
  },
  {
    title: "perfil",
    path: ROUTES.profile,
    icon: icon("ic_lock"),
  },
];

export default navConfig;

import { useState } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { usePartner } from "../../hooks/stripe";

const businessTypes = [
  { value: "INDIVIDUAL", title: "Individual" },
  { value: "BUSINESS", title: "Negócios" },
];

const renderSubmissionStatus = (partner) => {
  if (
    "stripeInfo" in partner &&
    partner.stripeInfo.status === "VALIDATION_FAILED"
  ) {
    return "A verificação de identidade falhou. Resolva todos os problemas mencionados para obter seu link de parceiro.";
  }

  return "A verificação de identidade está em andamento.";
};

export default function StripeForm() {
  const { createPartnerMutation, getPartnerQuery } = usePartner();
  const [businessType, setBusinessType] = useState("");

  const partner = getPartnerQuery.data;
  const setupAccountUrl = createPartnerMutation.data?.setupAccountUrl;

  const stripeIsReady =
    partner &&
    "stripeInfo" in partner &&
    partner.stripeInfo.status === "READY_TO_USE";

  const handleChangeBusinessType = (event) => {
    setBusinessType(event.target.value);
  };

  const onCreatePartner = async () => {
    await createPartnerMutation.mutateAsync({ businessType, country: "BR" });
    await getPartnerQuery.refetch();
  };

  const onCheckStatusClick = () => {
    getPartnerQuery.refetch();
  };

  if (getPartnerQuery.isFirstLoad) {
    return (
      <Stack mt={12} flex={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      {stripeIsReady ? (
        <Typography variant="body">
          Seu link de parceiro é: <Link>{partner.partnerLink}</Link>
        </Typography>
      ) : (
        <>
          {setupAccountUrl ? (
            <Stack gap={1}>
              <Typography variant="body">
                Vá para a página da web stripe para verificação de identidade
              </Typography>
              <Link
                sx={{ wordWrap: "break-word" }}
                href={setupAccountUrl}
                target="_blank"
              >
                {setupAccountUrl}
              </Link>
              <Stack mt={3}>
                <Stack direction="row" alignItems="flex-start" gap={1}>
                  <Typography fontWeight="bolder" variant="body">
                    Status atual:
                  </Typography>
                  {getPartnerQuery.isFetching ? (
                    <Skeleton sx={{ fontSize: "1rem", flex: 1 }} />
                  ) : (
                    renderSubmissionStatus(partner)
                  )}
                </Stack>
                {getPartnerQuery.isFetching && (
                  <Skeleton sx={{ fontSize: "1rem", flex: 1 }} />
                )}
              </Stack>

              <LoadingButton
                onClick={onCheckStatusClick}
                loading={getPartnerQuery.isFetching}
                size="large"
                type="submit"
                variant="contained"
              >
                Verificar status
              </LoadingButton>
            </Stack>
          ) : (
            <Stack gap={2}>
              <Typography variant="body">
                Crie uma conta stripe para obter todos os recursos da
                plataforma.
              </Typography>
              <Typography variant="body">
                Selecione um tipo de negócio para continuar
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Tipo</InputLabel>
                <Select
                  value={businessType}
                  label="Idade"
                  onChange={handleChangeBusinessType}
                >
                  {businessTypes.map(({ value, title }) => (
                    <MenuItem key={value} value={value}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LoadingButton
                loading={createPartnerMutation.isLoading}
                onClick={onCreatePartner}
                disabled={!businessType}
                size="large"
                type="submit"
                variant="contained"
              >
                Continuar
              </LoadingButton>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}

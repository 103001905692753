import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import {
  usePartnerGroupInfo,
  usePartnerGroupStatistic,
} from "../../../hooks/statistics";
import GroupsInfoChart from "../../../components/chart/GroupsInfoChart";
import GroupsStatisticsChart from "../../../components/chart/GroupsStatisticsChart";

const GroupsStatistics = ({ timeRange, dateRange }) => {
  const from = dateRange[0].startDate.toISOString().split("T")[0];
  const till = dateRange[0].endDate.toISOString().split("T")[0];

  const { data: groupInfoData } = usePartnerGroupInfo(from, till);

  const { data: groupStatisticsData } = usePartnerGroupStatistic(
    timeRange,
    from,
    till
  );

  return (
    <Stack gap={2}>
      <Typography variant="h4">Informações dos Grupos</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {groupStatisticsData ? (
            <GroupsStatisticsChart groupStatisticsData={groupStatisticsData} />
          ) : (
            <Skeleton variant="rounded" width="100%" height="389px" />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {groupInfoData ? (
            <GroupsInfoChart groupInfoData={groupInfoData} />
          ) : (
            <Skeleton variant="rounded" width="100%" height="389px" />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GroupsStatistics;

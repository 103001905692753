import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { DateRange } from "react-date-range";
import {
  usePartnerEarningsInfo,
  usePartnerEarningsStatistic,
} from "../../../hooks/statistics";
import EarningsStatisticsChart from "../../../components/chart/EarningsStatisticsChart";
import EarningsInfoChart from "../../../components/chart/EarningsInfoChart";
import EarningsGroupChart from "../../../components/chart/EarningsGroupChart";

const EarningsStatistics = ({
  timeRange,
  dateRange,
  setDateRange,
  setTimeRange,
}) => {
  const from = dateRange[0].startDate.toISOString().split("T")[0];
  const till = dateRange[0].endDate.toISOString().split("T")[0];

  const { data: earningsInfoData } = usePartnerEarningsInfo(from, till);
  const { data: earningsStatisticsData } = usePartnerEarningsStatistic(
    timeRange,
    from,
    till
  );

  const firstRender = useRef(false);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);

  const onDateChange = (e) => {
    setDateRange([e.selection]);
  };

  useEffect(() => {
    if (earningsInfoData && earningsStatisticsData && !firstRender.current) {
      firstRender.current = true;
      setMonthlyEarnings(
        earningsInfoData.reduce((acc, cur) => acc + cur.amount, 0)
      );
      setTotalEarnings(earningsStatisticsData.totalAmount);
    }
  }, [earningsInfoData, earningsStatisticsData]);

  return (
    <>
      <Typography variant="h3" mb={4}>
        Você ganhou{" "}
        <span style={{ color: "#2065D1" }}>R$ {totalEarnings.toFixed(2)}</span>{" "}
        com o Orbis até agora e seu ganho mensal é de{" "}
        <span style={{ color: "#2065D1" }}>
          R$ {monthlyEarnings.toFixed(2)}
        </span>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4}>
          <Stack sx={{ minWidth: "290px" }}>
            <Card sx={{ minWidth: "290px", height: "100%" }}>
              <Stack gap={2}>
                <Box paddingTop={2} px={1}>
                  <FormControl fullWidth>
                    <InputLabel>Faixa de tempo</InputLabel>
                    <Select
                      value={timeRange}
                      label="Faixa de tempo"
                      onChange={(e) => setTimeRange(e.target.value)}
                    >
                      <MenuItem value="MONTH">Mês</MenuItem>
                      <MenuItem value="DAY">Dia</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <DateRange
                  maxDate={new Date()}
                  editableDateInputs={false}
                  onChange={onDateChange}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                />
              </Stack>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          {earningsStatisticsData ? (
            <EarningsStatisticsChart
              earningsStatisticsData={earningsStatisticsData}
            />
          ) : (
            <Skeleton variant="rounded" width="100%" height="435px" />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {earningsInfoData ? (
            <EarningsGroupChart earningsInfoData={earningsInfoData} />
          ) : (
            <Skeleton variant="rounded" width="100%" height="435px" />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {earningsInfoData ? (
            <EarningsInfoChart earningsInfoData={earningsInfoData} />
          ) : (
            <Skeleton variant="rounded" width="100%" height="435px" />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EarningsStatistics;

import { useMutation } from "react-query";
import api from "../../api";
import { useUser } from "../auth";
import { queryKeys } from "../../react-query/constants";
import useQueryFirstLoad from "../useQueryFirstLoad";

const usePartner = () => {
  const { user } = useUser();

  // Get partner data
  const getPartnerQuery = useQueryFirstLoad(
    queryKeys.partner,
    async () => {
      try {
        const { data } = await api.get("/partner", {
          headers: {
            Authorization: `Bearer ${user.idToken}`,
          },
        });

        return data;
      } catch (e) {
        return null;
      }
    },
    { enabled: true }
  );

  // Create partner
  const createPartnerMutation = useMutation(async (requestData) => {
    const response = await api.post("/partner", requestData, {
      headers: {
        Authorization: `Bearer ${user.idToken}`,
      },
    });
    return response.data;
  });

  // Update partner
  const updatePartnerMutation = useMutation(async (requestData) => {
    const response = await api.patch("/partner", requestData, {
      headers: {
        Authorization: `Bearer ${user.idToken}`,
      },
    });
    return response.data;
  });

  return {
    getPartnerQuery,
    createPartnerMutation,
    updatePartnerMutation,
  };
};

export default usePartner;
